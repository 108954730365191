@import "~antd/dist/antd.dark.less";
@import "~antd/dist/antd.compact.less";
@import "~antd/dist/antd.less";

// -- Theming
@primary-color: darken(rgb(73, 255, 255), 30%);
@success-color: #38ee7b;

// -- Fonts
@font-face {
  font-family: "Main";
  src: url("./fonts/aux-mono.otf");
}

// -- Customs
.IndexPoolWidget {
  .ant-card-meta-description {
    padding-left: 12px !important;
    border-left: 2px solid rgb(73, 255, 255);
  }
}

.fancy {
  text-transform: uppercase !important;
  letter-spacing: 0.1ch !important;
  font-weight: bold;
}

.colorful {
  color: @success-color;
  font-weight: bold;
}

.wallet-option {
  &:hover {
    border-left: 3px solid #d1379a;
    padding-left: 5px;
    cursor: pointer;
  }
}

.ant-drawer-header {
  background-color: #151515;
}

.prominent-stat {
  .fancy;

  margin: 48px 0;

  .ant-statistic-content {
    .colorful;
  }
}

.with-background {
  background-image: url("../images/bg.svg");
  background-size: cover;
}

.fake-flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -40px 0 0 -40px;
  width: calc(100% + 40px);
  justify-content: space-evenly;
}

.fake-flex-gap > * {
  margin: 40px 0 0 40px;
}

@keyframes flashing-input-error {
  0% {
    border: 1px solid rgba(255, 0, 0, 0.5);
    background: rgba(255, 0, 0, 0.25);
  }
  50% {
    border: 1px solid rgba(255, 0, 0, 0.25);
    background: rgba(255, 0, 0, 0.1);
  }
  100% {
    border: 1px solid rgba(255, 0, 0, 0.5);
    background: rgba(255, 0, 0, 0.25);
  }
}

.input-with-error {
  animation: flashing-input-error 2s ease-in-out infinite;
}

@keyframes fading-out {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.4;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fading-out {
  animation: fading-out 5s ease-in-out forwards;
}

// -- Ant Overrides
@font-family: "Main";

.ant-page-header {
  border: none !important;
}

.ant-layout-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.8) !important;
}

.ant-menu {
  border: none !important;
}

.ant-card-head {
  border: none !important;
}

.ant-page-header-heading-title {
  line-height: 1 !important;
}

.ant-page-header-back-button {
  font-size: 28px !important;
}

.ant-back-top {
  z-index: 100 !important;
}

.ant-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bordered,
.ant-card-bordered {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ant-progress-circle .ant-progress-text {
  font-size: 12px;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}

.ant-descriptions-item-label {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ant-alert-info {
  border-radius: 16px;
}

// Buttons
.ant-btn {
  border-radius: 3px !important;
  border: 2px solid rgba(255, 255, 255, 0.2);

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.4);
  }
}

.ant-btn-default:hover {
  color: #d1379a;
}

.ant-btn-dangerous {
  color: #da3a3a;

  &:hover {
    color: #f86c6c;
  }
}
.ant-btn-primary.plus {
  background: -webkit-linear-gradient(
    55deg,
    darken(#ad92f7, 20%),
    darken(#44ca97, 20%) 80%
  );
  border: 2px solid rgba(255, 255, 255, 0.7);

  &:hover {
    background: -webkit-linear-gradient(55deg, #9259ec, #28ad7a 80%);
    border: 2px solid rgba(255, 255, 255, 0.7);
  }
}

.ant-card-hoverable {
  transition: border 0.2s ease-in-out;
  border: 2px solid rgba(255, 255, 255, 0.2);

  &:hover {
    border: 2px solid @success-color;
  }
}
